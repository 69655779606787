import { AppManifest } from '@wix/platform-editor-sdk';
import { fetchWidgetsStageData } from '@wix/bob-widget-services';
import { FULL_WIDGET_CONTROLLER_ID } from '../../constants';
import { setLoadMoreInPgPanel } from './setLoadMoreInPgPanel';

export const modifyAppManifest = async (
  manifestOptions,
): Promise<AppManifest> => {
  const [options, editorSDK] = manifestOptions;
  const { appManifestBuilder } = options;
  const baseManifest = await fetchWidgetsStageData(options);

  const manifest = appManifestBuilder.withJsonManifest(baseManifest).build();

  // Hide loadMore from pro gallery panel
  await setLoadMoreInPgPanel(manifest, editorSDK);
  // Hide elements panel on mobile
  manifest.controllersStageData[
    FULL_WIDGET_CONTROLLER_ID
  ].default.gfpp.mobile.iconButtons.add = { behavior: 'HIDE' };

  return manifest;
};
