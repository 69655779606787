import { ElementRoles } from '../../enums';

const COMPS_DATA = {
  header: {
    label: 'instagram-blocks.panel.elements.header',
    role: ElementRoles.HeaderContainer,
    tooltip: 'instagram-blocks.panel.elements.header.tooltip',
  },
  headerTitle: {
    label: 'instagram-blocks.panel.elements.title',
    role: ElementRoles.HeaderTitle,
  },
  headerUsername: {
    label: 'instagram-blocks.panel.elements.userName',
    role: ElementRoles.HeaderUserNameBtn,
  },
  headerHashtags: {
    label: 'instagram-blocks.panel.elements.hashtags',
    role: ElementRoles.HeaderHashtagBtn,
  },
  posts: {
    label: 'instagram-blocks.panel.elements.posts',
    role: ElementRoles.ProGallery,
  },
};

const CATEGORIES_IDS = {
  text: 'instagram-blocks.panel.elements.categories.text',
  feed: 'instagram-blocks.panel.elements.categories.feed',
};

export const categoriesData = [
  {
    id: CATEGORIES_IDS.text,
    title: CATEGORIES_IDS.text,
  },
  {
    id: CATEGORIES_IDS.feed,
    title: CATEGORIES_IDS.feed,
  },
];

export const elementsData = [
  {
    label: COMPS_DATA.header.label,
    identifier: { role: COMPS_DATA.header.role },
    categoryId: CATEGORIES_IDS.text,
    index: 0,
    tooltipData: {
      content: COMPS_DATA.header.tooltip,
    },
  },
  {
    label: COMPS_DATA.headerTitle.label,
    identifier: { role: COMPS_DATA.headerTitle.role },
    categoryId: CATEGORIES_IDS.text,
    index: 1,
  },
  {
    label: COMPS_DATA.headerUsername.label,
    identifier: { role: COMPS_DATA.headerUsername.role },
    categoryId: CATEGORIES_IDS.text,
    index: 2,
  },
  {
    label: COMPS_DATA.headerHashtags.label,
    identifier: { role: COMPS_DATA.headerHashtags.role },
    categoryId: CATEGORIES_IDS.text,
    index: 3,
  },
  {
    label: COMPS_DATA.posts.label,
    identifier: { role: COMPS_DATA.posts.role },
    categoryId: CATEGORIES_IDS.feed,
    index: 4,
  },
];
