import { ComponentRef, RemoveComponentHandler } from '@wix/platform-editor-sdk';
import { TFunction } from '@wix/yoshi-flow-editor';
import { createCategoriesData, createElementsData } from './elementsPanelUtils';
import { EditorSDKUtils } from '../../utils/Editor';
import { setManifestState } from '../../utils/Editor/setManifestState';
import { HeaderStates } from '../../enums';

export const openElementsPanel = async (
  editorSDK: any,
  widgetRef: ComponentRef,
  t: TFunction,
  isExpand: boolean = false,
  // flowAPI: EditorScriptFlowAPI
): Promise<void> => {
  const editorSDKUtils = new EditorSDKUtils(editorSDK);
  const getCollapsedRefComponentByRole = async (role: string) => {
    const [widgetRefHost] = await editorSDKUtils.getAncestors(widgetRef);
    const collapsedRefComponents =
      await editorSDKUtils.getCollapsedRefComponents(widgetRefHost, true);
    const collapsedRefComponent = collapsedRefComponents.filter(
      (comp) => comp.role === role,
    );
    return collapsedRefComponent[0]?.componentRef;
  };

  const getCompToHide = async (componentRef) => {
    const type = await editorSDKUtils.getType(componentRef);
    await disableElements(componentRef, true);
    return type.includes('AppWidget')
      ? (await editorSDKUtils.getAncestors(componentRef))[0]
      : /* istanbul ignore next reason: we don't hide whole widget */ componentRef;
  };

  const showComponent = async (componentRef) => {
    await disableElements(componentRef, false);
    await editorSDKUtils.expandReferredComponent(componentRef);
    // return editorSDKUtils.refreshLivePreview(false, 'AFTER_DB_CHANGE');
  };

  const hideComponent = async (componentRef) => {
    await editorSDKUtils.collapseReferredComponent(componentRef);
  };

  const getInstanceAndMsid = async () => {
    const msid = await editorSDKUtils.getMetaSiteId();
    const instance_id = await editorSDKUtils.getInstanceId();
    const instance = await editorSDKUtils.getInstance();
    return { msid, instance_id, instance };
  };

  const addComponentHandler = async (
    { role }: { role: string },
    compRef: ComponentRef | undefined,
  ): Promise<void> => {
    const componentRef =
      compRef || (await getCollapsedRefComponentByRole(role));
    const { msid, instance_id, instance } = await getInstanceAndMsid();

    return showComponent(componentRef);
    // .then(() => {
    //   elementsPanelBi({
    //     flowAPI,
    //     msid,
    //     instance_id,
    //     instance,
    //     role,
    //     compId,
    //     isOn: true,
    //   });
    // });
  };

  const removeComponentHandler: RemoveComponentHandler = async (
    compRef,
    { role },
  ): Promise<void> => {
    const compToHide = await getCompToHide(compRef);
    const { msid, instance_id, instance } = await getInstanceAndMsid();
    return hideComponent(compToHide);
    // .then(() => {
    //   elementsPanelBi({
    //     flowAPI,
    //     msid,
    //     instance_id,
    //     instance,
    //     role,
    //     compId,
    //     isOn: false,
    //   });
    // });
  };

  const elementsData = createElementsData(t, isExpand);
  const categoriesData = createCategoriesData(t, isExpand);

  const disableElements = async (
    componentRef: ComponentRef,
    shouldDisable: boolean,
  ): Promise<void> => {
    const state = shouldDisable ? HeaderStates.State2 : HeaderStates.Default;
    const type = await editorSDKUtils.getType(componentRef);
    const isContainerElement = type === 'mobile.core.components.Container';
    if (isContainerElement) {
      setManifestState(state, editorSDK).then(() =>
        setTimeout(() => {
          openElementsPanel(editorSDK, widgetRef, t, isExpand);
        }, 500),
      );
    }
  };

  return editorSDKUtils.openElementsPanel({
    widgetRef,
    elementsData,
    categoriesData,
    addComponentHandler,
    removeComponentHandler,
  });
};
