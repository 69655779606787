import { FlowAPI, FlowEditorSDK } from '@wix/yoshi-flow-editor';
import type { AppManifest } from '@wix/platform-editor-sdk';
import {
  EditorSDKUtils,
  installWidget,
  modifyAppManifest,
} from './utils/Editor';
import { editorAppEventListener } from './utils/Editor/editorEventListeners';

export const editorReady = async (
  editorSDK: FlowEditorSDK,
  appDefId: string,
  options: any,
  flowAPI: FlowAPI,
) => {
  if (options.firstInstall) {
    installWidget(editorSDK);
  }
  await editorAppEventListener(editorSDK, flowAPI, options);
};

export const getAppManifest = async (...options): Promise<AppManifest> => {
  return modifyAppManifest(options);
};

export const exports = (editorSDK) => ({
  editor: {
    getSelectedWidgetDesignPresetId: async ({ widgetRef }) => {
      const editorSDKUtils = new EditorSDKUtils(editorSDK);
      const selectedPreset = await editorSDKUtils.getPreset(widgetRef);
      return selectedPreset.style;
    },
  },
});
