import { EditorScriptFlowAPI, FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { EditorSDKUtils } from './editorSdkUtils';
import { getPresetNameById, mainWidgetPresets } from '../../constants';
import { PresetIds, PresetNames } from '../../enums';

type PresetListenerParams = {
  event: any;
  editorSDK: FlowEditorSDK;
  flowAPI: EditorScriptFlowAPI;
};

export function checkIfPreset(
  presetToCheck: PresetNames,
  presetNamesArr: PresetNames[],
) {
  for (const presetName of presetNamesArr) {
    if (presetToCheck === presetName) {
      return true;
    }
  }
  return false;
}

export async function presetListenerCallback({
  event,
  editorSDK,
  flowAPI,
}: PresetListenerParams) {
  const editorSDKUtils = new EditorSDKUtils(editorSDK);
  //   const msid = await editorSDKUtils.getMetaSiteId();
  //   const instance_id = await editorSDKUtils.getInstanceId();
  const { preset, componentRef } = event.detail;

  const prevPresetId = (await editorSDKUtils.getPreset(componentRef))
    .layout as PresetIds;
  const selectedPreset = mainWidgetPresets().find((prst) => prst.id === preset);
  const mobilePresetId = selectedPreset?.connectedMobilePresetID;
  const currentPresetName = getPresetNameById(preset) as PresetNames;
  const prevPresetName = getPresetNameById(prevPresetId) as PresetNames;
  const isGrid = checkIfPreset(PresetNames.Grid, [
    currentPresetName,
    prevPresetName,
  ]);
  console.log('Preset=>', {
    preset,
    selectedPreset,
    prevPresetName,
    currentPresetName,
    prevPresetId,
    isGrid,
  });

  //   const instance = await editorSDKUtils.getInstance();
  //   const compId = componentRef.id.split('_')[0];

  //   await presetsPanelBi({
  //     flowAPI,
  //     msid,
  //     instance_id,
  //     instance,
  //     preset,
  //     compId,
  //   });

  // const getConnectedMobilePresetId = (preset: string) => {
  //   return selectedPreset?.connectedMobilePresetID;
  // };

  const [parentRefComponent] = await editorSDKUtils.getAncestors(componentRef);

  const props = await editorSDKUtils.getWidgetProps(componentRef);
  const newProps = JSON.parse(JSON.stringify(props));
  if (preset && parentRefComponent) {
    await editorSDKUtils.removeAllOverrides(parentRefComponent);
    await editorSDKUtils.changePreset(parentRefComponent, 'DESKTOP', preset);

    if (mobilePresetId) {
      await editorSDKUtils.changePreset(
        parentRefComponent,
        'MOBILE',
        mobilePresetId,
      );
    }
  }

  if (selectedPreset?.defaultHeight) {
    editorSDKUtils.setWidgetHeight(
      parentRefComponent,
      selectedPreset.defaultHeight,
    );
  }

  newProps.preset = currentPresetName;
  await editorSDKUtils.setWidgetProps({ componentRef, newProps });
  await editorSDKUtils.reloadManifest();
  return componentRef;
}
